<template>
  <div v-if="selectingApp?._id">
    <div class="row no-wrap items-center">
      <q-space />
      <div class="q-gutter-md q-pb-md">
        <q-btn no-caps v-if="userInfo?.canWriteApp && !isNew" color="red" @click="onDelete(form)">
          {{ $t('deletd_this_app') }}
        </q-btn>
        <q-btn no-caps flat @click="onCancel" class="btn-cancel">{{ $t('cancel') }}</q-btn>
        <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
      </div>
    </div>
    <q-form ref="formRef" greedy>
      <q-card flat bordered>
        <q-card-section>
          <div class="row">
            <div class="col-12 col-lg-6 vertical-line q-pr-md q-mr-md">
              <div class="title">LINEアカウント名</div>
              <label class="sub-title">LINEアカウント名を入力してください</label>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.appName]"
                outlined
                v-model="selectingApp.name"
                dense
                :placeholder="$t('label.app_name')"
              />

              <div class="title">{{ $t('label.chanel_access_token') }}</div>
              <label class="sub-title">LINE Developersから取得したチャネルアクセストークンを入力してください</label>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.chanelAccessToken]"
                outlined
                v-model="selectingApp.token"
                dense
                :placeholder="$t('label.chanel_access_token')"
              />
              <div class="title">{{ $t('label.chanel_secret') }}</div>
              <label class="sub-title">LINE Official Account Managerから取得したChannel secretを入力してください</label>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.chanelSecret]"
                outlined
                v-model="selectingApp.secret"
                dense
                :placeholder="$t('label.chanel_secret')"
              />
            </div>
            <div class="q-pr-md">
              <div v-if="selectingApp.account_id">
                <div class="title">{{ $t('label.qr_code') }}</div>
                <q-avatar rounded size="100px">
                  <img :src="getQRCode(selectingApp.account_id)" />
                </q-avatar>
              </div>
            </div>
            <div>
              <div class="title">{{ $t('label.app_url') }}</div>
              <div class="q-mt-sm">
                <a :href="webhookURL" target="_blank" class="underline-link">{{ webhookURL }}</a>
              </div>
              <div v-if="selectingApp.account_id" class="title q-mt-sm">{{ $t('label.line_profile') }}</div>
              <div v-if="selectingApp.account_id" class="q-mt-sm">
                <a :href="lineProfileURL" target="_blank" class="underline-link">{{ lineProfileURL }}</a>
              </div>
            </div>
          </div>
          <div class="row"><div class="full-width horizontal-line-dotted q-mb-md"></div></div>
          <div class="row">
            <div class="col-12 col-lg-6 vertical-line q-pr-md q-pb-md">
              <div class="title">{{ $t('label.traffic_source.liff_id') }}</div>
              <label class="sub-title">LINE Developersから取得したLIFF IDを入力してください</label>
              <q-input
                lazy-rules
                outlined
                v-model="selectingApp.liff_id"
                dense
                :placeholder="$t('label.traffic_source.liff_id')"
              />
            </div>
            <div class="q-pr-md"></div>
            <div>
              <div class="title">LIFF コールバックURL</div>
              <div class="q-mt-sm">
                <a :href="liffWebhookURL" target="_blank" class="underline-link">{{ liffWebhookURL }}</a>
              </div>
              <label class="sub-title"
                >LINE誘導バナー、流入元分析を有効にするために、LIFFページからLINE User IDが送信されます。</label
              >
            </div>
          </div>
        </q-card-section>
      </q-card>
      <q-card flat bordered class="q-mt-md">
        <q-card-section>
          <div class="row">
            <div class="col-12 col-lg-6 vertical-line q-pr-md q-mr-md">
              <q-toggle round v-model="selectingApp.is_test_mode" :label="$t('label.test_mode')" />
              <div class="title">{{ $t('label.chanel_access_token_test_mode') }}</div>
              <label class="sub-title">LINE Developersから取得したチャネルアクセストークンを入力してください</label>
              <q-input
                :disable="!selectingApp.is_test_mode"
                lazy-rules
                outlined
                v-model="selectingApp.token_test"
                :rules="[(val) => (val && val.length > 0) || requiredRule.chanelAccessTokenTest]"
                dense
                :placeholder="$t('label.chanel_access_token_test_mode')"
              />
              <div class="title">{{ $t('label.chanel_secret_test_mode') }}</div>
              <label class="sub-title">LINE Official Account Managerから取得したChannel secretを入力してください</label>
              <q-input
                :disable="!selectingApp.is_test_mode"
                lazy-rules
                outlined
                v-model="selectingApp.secret_test"
                :rules="[(val) => (val && val.length > 0) || requiredRule.chanelSecretTest]"
                dense
                :placeholder="$t('label.chanel_secret_test_mode')"
              />
            </div>
            <div class="q-pr-md q-pb-md">
              <div v-if="selectingApp.account_id_test">
                <div class="title">{{ $t('label.qr_code') }}</div>
                <q-avatar rounded size="100px">
                  <img :src="getQRCode(selectingApp.account_id_test)" />
                </q-avatar>
              </div>
            </div>
            <div>
              <div class="title">{{ $t('label.app_url') }}</div>
              <div class="q-mt-sm">
                <a :href="webhookTestURL" target="_blank" class="underline-link">{{ webhookTestURL }}</a>
              </div>
              <div v-if="selectingApp.account_id_test" class="title q-mt-sm">{{ $t('label.line_profile') }}</div>
              <div v-if="selectingApp.account_id_test" class="q-mt-sm">
                <a :href="lineProfileTestURL" target="_blank" class="underline-link">{{ lineProfileTestURL }}</a>
              </div>
            </div>
          </div>
          <div class="row"><div class="full-width horizontal-line-dotted q-mb-md"></div></div>
          <div class="row">
            <div class="col-12 col-lg-6 vertical-line q-pr-md q-pb-md">
              <div class="title">LIFF ID（テストモード）</div>
              <label class="sub-title">LINE Developersから取得したLIFF IDを入力してください</label>
              <q-input
                lazy-rules
                outlined
                v-model="selectingApp.liff_id_test"
                dense
                :placeholder="$t('label.traffic_source.liff_id')"
              />
            </div>
            <div class="q-pr-md"></div>
            <div>
              <div class="title">LIFF コールバックURL</div>
              <div class="q-mt-sm">
                <a :href="liffTestWebhookURL" target="_blank" class="underline-link">{{ liffTestWebhookURL }}</a>
              </div>
              <label class="sub-title"
                >LINE誘導バナー、流入元分析を有効にするために、LIFFページからLINE User IDが送信されます。</label
              >
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-form>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IApp } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import AppForm from '@/components/app/AppForm.vue'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { UserModel } from '@/models/user-model'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: {
    AppForm,
    TagContent,
  },
  directives: { maska },
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class AppInformation extends mixins(AppMixin, BaseFormMixin) {
  selectingApp: IApp = {}

  get requiredRule() {
    const requiredRule = {
      appName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app_name'),
      }),
      chanelAccessToken: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token'),
      }),
      chanelSecret: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret'),
      }),
      chanelAccessTokenTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token_test_mode'),
      }),
      chanelSecretTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret_test_mode'),
      }),
    }
    return requiredRule
  }

  get isNew() {
    return !this.selectingApp?._id
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get webhookURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/${this.selectingApp.app_prefix}`
    return url
  }

  get webhookTestURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/test/${this.selectingApp.app_prefix}`
    return url
  }

  get lineProfileURL() {
    const url = `https://page.line.me/?accountId=${this.selectingApp.account_id}&openQrModal=true`
    return url
  }

  get lineProfileTestURL() {
    const url = `https://page.line.me/?accountId=${this.selectingApp.account_id_test}&openQrModal=true`
    return url
  }

  get liffWebhookURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/liff-app?app_id=${this.selectingApp._id}`
    return url
  }

  get liffTestWebhookURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/test/liff-app?app_id=${this.selectingApp._id}`
    return url
  }

  getQRCode(account_id) {
    const url = `https://qr-official.line.me/sid/m/${account_id}.png`
    return url
  }

  async fetchSelectingApp() {
    if (!this.appId) {
      this.selectingApp = {}
      return
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)

    this.parentForm = this.selectingApp
    this.initForm = cloneDeep(this.parentForm)
  }

  @Watch('appId', { immediate: true })
  appIdChanged() {
    // [AppMixin]
    this.fetchSelectingApp()
  }

  onCancel() {
    return true
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    if (this.isNew) {
      success = await dispatch(ACTION_APP.ADD_NEW, {
        ...this.selectingApp,
      })
    } else {
      success = await dispatch(ACTION_APP.UPDATE, {
        _id: this.selectingApp._id,
        ...this.selectingApp,
      })
    }

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.initForm = cloneDeep(this.parentForm)
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-weight: bold;
}

.sub-title {
  font-size: 8px !important;
}

.horizontal-line-dotted {
  border-bottom: 1px dotted #d0d2e0;
}

.vertical-line {
  border-right: 1px dotted #d9d9d9;
}

.underline-link {
  text-decoration: underline;
}
</style>
